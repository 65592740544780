import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      className="container"
      style={{ margin: "190px auto", textAlign: "center" }}
    >
      <h1>Hmm... we could not find that page</h1>
    </div>
  </Layout>
)

export default NotFoundPage
